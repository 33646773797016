<!--
 * @Description: 消息中心
 * @Author: ChenXueLin
 * @Date: 2021-10-20 14:15:17
 * @LastEditTime: 2022-04-21 15:43:56
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <el-button type="text" class="handleRead" @click="handleRead"
            >全部已读</el-button
          >
          <div class="message-box">
            <div
              ref="list"
              class="message-list"
              v-infinite-scroll="load"
              style="overflow:auto"
              infinite-scroll-disabled="disabled"
            >
              <div
                class="message-box"
                v-for="item in tableData"
                :key="item.remindId"
                @click="handleClick(item)"
              >
                <div class="order-title">
                  <span class="pot" v-if="item.readStatus == 0"></span>
                  {{ item.title }}
                </div>
                <div class="time">{{ item.createdTime }}</div>
              </div>
            </div>
            <div class="message-detail" v-if="currentInfo.title">
              <div class="title">{{ currentInfo.title }}</div>
              <div class="time">{{ currentInfo.createdTime }}</div>
              <div class="content">
                {{ currentInfo.content }}
              </div>
              <el-button type="text" @click="toDetail">查看详情</el-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { getMessageList, readMessage, handleUnread } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "messageCenter",
  components: {},
  data() {
    return {
      searchForm: {
        platform: 1,
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: [],
      total: 0,
      currentInfo: {},
      disabled: false
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {},
  methods: {
    load() {
      if (this.tableData.length == this.total) {
        return;
      }
      this.searchForm.pageIndex++;
      this.queryList();
    },
    //获取消息列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getMessageList(this.searchForm);
        let tableData = res.data.array;
        this.tableData = this.tableData.concat(tableData);
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //全部已读
    async handleRead() {
      try {
        let res = await readMessage();
        if (res.code == "OK") {
          this.searchForm.pageIndex = 1;
          this.tableData = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击左侧提醒
    handleClick(item) {
      this.currentInfo = item;
      if (item.readStatus == 0) {
        //如果未读，点击后请求接口
        this.currentInfo.readStatus = 1;
        this.handleUnread(item.subId);
      }
    },
    async handleUnread(subId) {
      try {
        let res = await handleUnread({ subId });
        if (res.code == "OK") {
          this;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击详情
    toDetail() {
      switch (this.currentInfo.formName) {
        case "XtdTeamWorkDetail": //协同单
          this.routerPush({
            name: "teamWork/teamWorkDetail",
            params: {
              refresh: true,
              synergyId: this.currentInfo.formConstructMap.synergyId
            }
          });
          break;
        case "taskWork": //任务单
          this.routerPush({
            name: "taskListManage/detail",
            params: {
              refresh: true,
              taskId: this.currentInfo.formConstructMap.taskId
            }
          });
          break;
        case "ServiceWork": //服务单
          this.routerPush({
            name: "serviceListManage/serviceDetail",
            params: {
              refresh: true,
              serviceId: this.currentInfo.formConstructMap.serviceId
            }
          });
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
}
.handleRead {
  margin-left: 20px;
  margin-top: 20px;
}
.message-box {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 5px;
  .message-list {
    width: 280px;
    padding: 15px;
    box-sizing: border-box;
    border-right: 1px solid #edf0f5;
    height: 100%;
    padding-top: 0px;
    overflow-y: auto;
    .message-box {
      height: 75px;
      display: block;
      width: 100%;
      background: #f0f3f7;
      border-radius: 3px;
      padding: 19px 15px;
      box-sizing: border-box;

      .order-title {
        font-size: 14px;
        color: #303133;
        .pot {
          background-color: #f56c6c;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
      }
      .time {
        font-size: 12px;
        color: #898c91;
        margin-top: 10px;
      }
    }
  }
  .message-detail {
    padding: 25px;
    box-sizing: border-box;
    width: calc(100% - 280px);
    .title {
      font-size: 18px;
      color: #303133;
    }
    .time {
      font-size: 12px;
      color: #898c91;
      margin-top: 13px;
    }
    .content {
      margin-top: 38px;
      font-size: 16px;
      color: #606266;
    }
    .el-button {
      margin-top: 40px;
    }
  }
}
</style>
